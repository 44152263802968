// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artikler-index-js": () => import("./../../../src/pages/artikler/index.js" /* webpackChunkName: "component---src-pages-artikler-index-js" */),
  "component---src-pages-complete-js": () => import("./../../../src/pages/complete.js" /* webpackChunkName: "component---src-pages-complete-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-customers-page-js": () => import("./../../../src/templates/customers-page.js" /* webpackChunkName: "component---src-templates-customers-page-js" */),
  "component---src-templates-faq-page-js": () => import("./../../../src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-pricing-page-js": () => import("./../../../src/templates/pricing-page.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

